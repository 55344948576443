import React from "react"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyIcon, EmailIcon } from "./image"

class Buttons extends React.Component {
  state = {
    value: this.props.pageurl,
    subject: this.props.pagetitle,
    langcode: this.props.langcode,
    copied: false,
  };

  onCopy = () => {
    this.setState({copied: true});
    setTimeout(() => {
       this.setState({copied: false});
    }, 2000);
  };	

  render() {

		const translatedSharedButtons = {
			'en' : 'Share by Email',
			'zh-hans' : '通过电子邮件分享',
			'fr' : 'Partager par email',
			'es' : 'Compartir por correo electrónico',
			'ru' : 'Oтправить по электронной почте',
			'pt-pt' : 'Compartilhar por e-mail',
		};	
		const translatedCopiedButtons = {
			'en' : 'Copy to clipboard',
			'zh-hans' : '复制到剪贴板',
			'fr' : 'Copier dans le presse-papier',
			'es' : 'Copiar al portapapeles',
			'ru' : 'Скопировать в буфер обмена',
			'pt-pt' : 'Copiar para área de transferência',
		};	

  	return (
  		<>
	  		<div className="share-buttons">
					<a className="resp-sharing-button__link" href={"mailto:?subject="+this.state.subject+"&body="+this.state.value} target="_self" rel="noopener" aria-label="Share by E-Mail">
					  <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large">
						   <EmailIcon />
						   {translatedSharedButtons[this.state.langcode]}
						</div>
					</a>
					<CopyToClipboard className="copy-button__link" onCopy={this.onCopy} text={this.state.value}>
					  <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large">
					    <CopyIcon />
					    {translatedCopiedButtons[this.state.langcode]}
					  </div>
					</CopyToClipboard>
					<section className="copied-link">
					  {this.state.copied ? <span>Copied</span> : null}
					</section>
				</div>
			</>
  	);
  }
};


export default Buttons;