import { Link } from "gatsby"
import React from "react"
import { Image } from "./image"
import { ClearImage } from "./image"

class Header extends React.Component {
  constructor(props) {
    super(props);
    // This binding is necessary to make `this` work in the callback
    this.hamburgerClick = this.hamburgerClick.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad())
  }

  hamburgerClick(e) {
    document.querySelector('.language_select').classList.toggle('dropdown-display')
    document.querySelector('.header-languages').classList.toggle('languages-hide')
  }

  handleLoad(e) {
    if(document.querySelector(".langcodes.zh-hans")){
      document.querySelector(".langcodes.zh-hans").innerHTML = '汉语'
      document.querySelector(".langcodes.pt-pt").innerHTML = 'PT'
    }
  }

  render() {

    let languageList;
    if(this.props.langsToCall[0].id){
      // If we are on the index page
      languageList = (
        <ul className="language_select">
          {
            this.props.langsToCall.map( (lang) => (
              <li key={lang.argument}>
                <Link 
                className={(() => {
                  switch(this.props.currentLang === lang.id) {
                    case true: return `langcodes active ${lang.id}`;
                    default:  return `langcodes ${lang.id}`;
                  }
                }) ()} 
                to={(() => {
                  switch(lang.id) {
                    case "en": return "/";
                    default:  return `/${lang.id}`;
                  }
                }) ()} 
                >{lang.argument.toUpperCase()}</Link>
              </li> 
            ))
          }
        </ul>
      );
    } else {
      // If we are on a node page
      languageList = (
        <ul className="language_select">
        {
          this.props.langsToCall.map( (lang) => (
            <li key={lang.entityLanguage.argument}>
              <Link 
              className={(() => {
                switch(this.props.currentLang === lang.path.langcode) {
                  case true: return `langcodes active ${lang.path.langcode}`;
                  default:  return `langcodes ${lang.path.langcode}`;
                }
              }) ()} 
              to={(() => {
                switch(lang.path.langcode) {
                  case "en": return `${lang.path.alias}`;
                  default:  return `/${lang.path.langcode}${lang.path.alias}`;
                }
              }) ()} 
              >{lang.entityLanguage.argument.toUpperCase()}</Link>
            </li> 
          ))
        }
        </ul>
      );
    }

    return (
      <header>
        <div className="header-container">
          <div className="header-logo">
            <Link 
            to={(() => {
              switch(this.props.currentLang) {
                case "en": return `/`;
                case "fr": return `/fr`;
                case "es": return `/es`;
                case "ru": return `/ru`;
                case "pt-pt": return `/pt-pt`;
                case "zh-hans": return `/zh-hans`;
                default:  return `/`;
              }
            }) ()} 
            ><Image />Growth Platform</Link>
          </div>

          <Link 
            to={(() => {
              switch(this.props.currentLang) {
                case "en": return `/`;
                case "fr": return `/fr`;
                case "es": return `/es`;
                case "ru": return `/ru`;
                case "pt-pt": return `/pt-pt`;
                case "zh-hans": return `/zh-hans`;
                default:  return `/`;
              }
            }) ()} 
            className="clear-button"
          ><ClearImage /></Link>

          <div className="header-languages">
            <div className="hamburger-menu" role="button" tabIndex="0" onClick={this.hamburgerClick} onKeyDown={this.hamburgerClick}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="language-group">
              <span>Language</span>
              {languageList}
            </div>
          </div>
        </div>
      </header>
    )
  }
};

export default Header;
